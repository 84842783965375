<template>
    <div ref="notificationRef" class="dropdown nav-item notification-dropdown btn-group">
        <span @click="openNotification = !openNotification" class="btn dropdown-toggle btn-icon-only nav-link">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-bell"
            >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <span v-if="notificationList.length" class="badge badge-success"></span>
        </span>
        <Transition name="fade">
            <perfect-scrollbar v-show="openNotification" :class="{ 'more-than-four': notificationList.length > 4 }">
                <ul @click.ctrl="readAllNotifications()" v-if="notificationList.length" class="notifications">
                    <li v-for="notification in notificationList" :key="notification.id" class="single-notification">
                        <div>
                            <span :class="[tierClass(notification.tiers_of_alert)]" class="light"></span>
                            <span class="message">
                                <h6>{{ notification.subject }}</h6>
                                <p>{{ notification.content }}</p>
                                <small>{{ getTimeDifference(notification.created_at) }}</small>
                            </span>
                            <button @click="readNotification(notification)" class="close">
                                <!-- <i class="fa-solid fa-circle-check"></i> -->
                                <i class="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                    </li>
                </ul>
                <p class="text-center mt-2 mb-2" v-else>There's no notification</p>
            </perfect-scrollbar>
        </Transition>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { getApplicantNotificationsApi, readApplicantNotificationsApi } from '@/api/applicant';
    import { useDate } from '@/composables/useDate';
    import useDetectOutsideClick from '@/composables/useDetectOutsideClick';

    const route = useRoute();
    const { getTimeDifference } = useDate();

    const openNotification = ref(false);
    const notificationRef = ref(null);

    const notificationList = ref([]);

    //onMounted(() => {
    //    // getApplicantNotificationList();
    //});

    const getApplicantNotificationList = async () => {
        const { data } = await getApplicantNotificationsApi({ page: { number: 1, size: 1000 }, list_type: 1 });
        notificationList.value = data.result.list;
    };

    const readNotification = async ({ id }) => {
        await readApplicantNotificationsApi({ ids: [id] });
        await getApplicantNotificationList();
    };

    const readAllNotifications = async() => {
        const idArray = notificationList.value.map((n) => n.id);
        await readApplicantNotificationsApi({ ids: idArray });
        await getApplicantNotificationList();
    };
    //點擊外面關閉notification list
    useDetectOutsideClick(notificationRef, () => {
        openNotification.value = false;
    });

    const tierClass = (tier) => {
        const key = tier.key;
        if (key === 1 || key === 22) {
            return 'yellow';
        }
        if (key === 2) {
            return 'orange';
        }
        if (key === 3) {
            return 'red';
        }
    };

    watch(
        () => route.name,
        () => {
            getApplicantNotificationList();
        }
    );
</script>

<style lang="scss" scoped>
    .ps {
        // height: 350px;
        // overflow: auto;
        position: absolute;
        top: 45px;
        right: -20px;
        min-width: 18rem;
        border-radius: 6px;
        box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
        background: #fff;
    }
    .more-than-four {
        height: 350px;
        overflow: auto;
    }
    .notifications {
        * {
            margin: 0;
        }
        .single-notification {
            cursor: default;
            padding: 15px 15px 0 15px;
            div {
                display: flex;
                justify-content: space-between;
                gap: 0 5px;
                padding-bottom: 15px;
            }
            &:not(:last-child) {
                div {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                }
            }
            .light {
                margin: 6px 5px 0 0;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                flex-shrink: 0;
            }
            .message {
                flex-grow: 1;
                h6 {
                    font-size: 14px;
                    font-weight: 600;
                }
                p {
                    font-size: 13px;
                    color: $m-color_6;
                }
            }
            button {
                border: none;
                outline: none;
                background: none;
                font-size: 18px;
                width: 30px;
            }
            .check {
                color: $success;
            }
            .close {
                color: $m-color_5;
                &:hover {
                    color: black;
                }
            }
        }
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.6s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transform: translateY(10%);
    }
</style>
