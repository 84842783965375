<template>
    <div class="layout-px-spacing dash_1">
        <teleport v-if="settings.layout === 'app'" to="#breadcrumb">
            <ul class="navbar-nav flex-row ms-auto">
                <li class="nav-item more-dropdown">
                    <div class="dropdown custom-dropdown-icon">
                        <a href="javascript:;" class="nav-link dropdown-toggle" id="ddlSettings" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>Settings</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlSettings">
                            <li><a class="dropdown-item" data-value="Settings" href="javascript:void(0);">Settings</a></li>
                            <li><a class="dropdown-item" data-value="Mail" href="javascript:void(0);">Mail</a></li>
                            <li><a class="dropdown-item" data-value="Print" href="javascript:void(0);">Print</a></li>
                            <li><a class="dropdown-item" data-value="Download" href="javascript:void(0);">Download</a></li>
                            <li><a class="dropdown-item" data-value="Share" href="javascript:void(0);">Share</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="row layout-top-spacing">
            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="personal-info widget card component-card_3">
                    <div class="card-body">
                        <div class="profile-picture">
                            <img v-if="avatar" :src="avatar" class="card-img-top" alt="profile-picture" />
                            <img v-else src="@/assets/images/logo-150.png" class="card-img-top" alt="profile-picture" />
                        </div>
                        <h5 class="fullname card-user_name text-primary">{{ fullName }}</h5>
                        <div v-if="!meStore.meInfo.is_super_admin">
                            <p class="card-user_occupation">Department: {{ jobCodes }}</p>
                            <p class="card-text">Employement: {{ meStore.jobCodes[0]?.detail.employee_status }}</p>
                            <p class="card-text">Employee No. {{ meStore.meInfo.employee_no }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>
                            Summary <span v-if="startDate && endDate">({{ startDate }} ~ {{ endDate }})</span>
                        </h5>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-tag"
                                >
                                    <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                                    <line x1="7" y1="7" x2="7" y2="7"></line>
                                </svg>
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Total hours required to work</h6>
                                    <p class="summary-count">{{ scheduledHours }} h</p>
                                </div>
                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div
                                            role="progressbar"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            aria-valuenow="90"
                                            class="progress-bar bg-gradient-secondary"
                                            :style="scheduleTimePercentage"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-tag"
                                >
                                    <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                                    <line x1="7" y1="7" x2="7" y2="7"></line>
                                </svg>
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Total hours worked</h6>
                                    <p class="summary-count">{{ actualHours }} h</p>
                                </div>
                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="65" class="progress-bar bg-gradient-success" :style="workedTimePercentage"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget">
                    <div class="widget-heading">
                        <h5>Accrual Hours</h5>
                    </div>
                    <div class="panel-body text-center">
                        <div class="icon--counter-container">
                            <div class="counter-container">
                                <div class="counter-content">
                                    <h1 class="ico-counter1 ico-counter">{{ vacationHours }}</h1>
                                </div>

                                <p class="ico-counter-text">Vacation Leave</p>
                            </div>

                            <div class="counter-container">
                                <div class="counter-content">
                                    <h1 class="ico-counter2 ico-counter">{{ sickHours }}</h1>
                                </div>

                                <p class="ico-counter-text">Sick Leave</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-recent-activity">
                    <div class="widget-heading">
                        <h5>Recent Notifications</h5>
                    </div>
                    <div class="widget-content">
                        <perfect-scrollbar v-if="notificationList.length" class="timeline-line">
                            <div v-for="notification in notificationList" :key="notification.id" class="item-timeline">
                                <div :class="[tierClass(notification.tiers_of_alert)]" class="badge"></div>
                                <div class="t-text">
                                    <p class="notification">
                                        <span>{{ notification.subject }}</span>
                                        {{ notification.content }}
                                    </p>
                                    <p class="t-time">{{ getTimeDifference(notification.created_at) }}</p>
                                </div>
                            </div>
                        </perfect-scrollbar>
                        <p v-else class="text-center">There's no recent notification</p>

                        <!-- <div class="tm-action-btn">
                            <button type="button" class="btn btn-default">
                                <span>View All</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-arrow-right"
                                >
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                </svg>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import '@/assets/sass/widgets/widgets.scss';
    import { onMounted, computed, ref } from 'vue';
    import { useSettings } from '@/store';
    import { useMe } from '@/store/me';
    import { getNotificationsApi, getMeApi } from '@/api/me';
    import { getWorkPeriodTimeAPI } from '@/api/dashboard';
    import { useMeta } from '@/composables/use-meta';
    import { useDate } from '@/composables/useDate';
    import '@/assets/sass/components/custom-counter.scss';

    useMeta({ title: 'Dashboard' });

    const settings = useSettings();
    const meStore = useMe();
    const notificationList = ref([]);
    const { getTimeDifference, USDateFormat } = useDate();

    //summary info
    const startDate = ref('');
    const endDate = ref('');

    const totalHours = ref(0);
    const scheduledHours = ref(0);
    const actualHours = ref(0);
    const sickHours = ref(0);
    const vacationHours = ref(0);
    const avatar = ref('');

    onMounted(() => {
        getAvatar();
        getNotificationList();
        getWorkPeriodTime();
    });

    const fullName = computed(() => {
        if (meStore.firstName && meStore.lastName) {
            return meStore.lastName + ', ' + meStore.firstName;
        }
        return '';
    });

    const jobCodes = computed(() => {
        if (meStore.jobCodes.length) {
            const mappedJobCodes = meStore.jobCodes.map((job) => `${job.department_name} (${job.position_name})`);
            return mappedJobCodes.join('、');
        }
        return '';
    });

    //排班時數佔可工作時數百分百
    const scheduleTimePercentage = computed(() => {
        if (!totalHours.value || !scheduledHours.value) {
            return 0;
        } else {
            return 'width:' + (scheduledHours.value / totalHours.value).toFixed(2) * 100 + '%';
        }
    });

    //已工作時數累積百分比
    const workedTimePercentage = computed(() => {
        if (!scheduledHours.value || !actualHours.value) {
            return 0;
        } else {
            return 'width:' + (actualHours.value / scheduledHours.value).toFixed(2) * 100 + '%';
        }
    });

    const getNotificationList = async () => {
        const { data } = await getNotificationsApi({ page: { number: 1, size: 1000 }, list_type: 2, hours_ago: 8 });
        notificationList.value = data.result.list;
    };

    const getWorkPeriodTime = async () => {
        const { data } = await getWorkPeriodTimeAPI();
        const info = data.result;
        startDate.value = USDateFormat(info.start_date);
        endDate.value = USDateFormat(info.end_date);

        totalHours.value = info.total_hours;
        scheduledHours.value = info.schedule_hours;
        actualHours.value = info.actual_hours;
        sickHours.value = +info.vacation_hours?.sick || 0;
        vacationHours.value = +info.vacation_hours?.vacation || 0;
    };

    const convertHourToHourAndMin = (time) => {
        if (time) {
            let hours = Math.floor(time);
            let minutes = Math.round((time - hours) * 60);
            return `${hours} h ${minutes} m`;
        }
        return '0h 0m';
    };

    const getAvatar = async () => {
        const { data } = await getMeApi();
        avatar.value = data.result.avatar;
    };

    const tierClass = (tier) => {
        const key = tier.key;
        if (key === 1 || key === 22) {
            return 'yellow';
        }
        if (key === 2) {
            return 'orange';
        }
        if (key === 3) {
            return 'red';
        }
    };
</script>
<style lang="scss" scoped>
    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 10px 0;
        p {
            margin: 0;
        }

        .profile-picture {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }
    }
    .item-timeline {
        cursor: default !important;
        .t-text {
            flex-wrap: wrap;
        }
    }
    @media screen and (max-width: 768px) {
        .t-text {
            flex-direction: column;
            align-items: start !important;
        }
    }
    .notification {
        span {
            font-weight: 700;
            margin-right: 5px;
        }
    }
    @media screen and (max-width: 768px) {
        .item-timeline {
            align-items: start !important;
            .badge {
                margin-top: 5px;
            }
            .notification {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .icon--counter-container {
        .counter-content {
            width: 120px;
            height: 120px;
            h1 {
                color: $primary;
            }
        }
    }
</style>
