import service from './api';

export const getMeApi = () => {
    return service({
        url: '/api/v1/me',
        method: 'GET',
    });
};
export const updateMeApi = (data) => {
    return service({
        url: '/api/v1/me',
        method: 'PUT',
        data,
    });
};

export const getMeJobCodeApi = () => {
    return service({
        url: '/api/v1/me/job-codes',
        method: 'GET',
    });
};

export const getMeEntitiesApi = () => {
    return service({
        url: '/api/v1/me/entities',
        method: 'GET',
    });
};

export const changeEntityApi = (id) => {
    return service({
        url: `/api/v1/me/entities/${id}/change`,
        method: 'POST',
    });
};

export const getMenuListApi = () => {
    return service({
        url: '/api/v1/me/menu',
        method: 'GET',
    });
};
export const getPermissionsApi = () => {
    return service({
        url: '/api/v1/me/permissions',
        method: 'GET',
    });
};
export const updatePasswordApi = (data) => {
    return service({
        url: '/api/v1/me/password',
        method: 'PATCH',
        data,
    });
};
export const updatePinCodeApi = (data) => {
    return service({
        url: '/api/v1/me/pin-code',
        method: 'PUT',
        data,
    });
};

export const getJobcodeOptionsApi = () => {
    return service({
        url: '/api/v1/me/job-code/options',
        method: 'GET',
    });
};

export const getNotificationsApi = (data) => {
    return service({
        url: '/api/v1/me/notifications',
        method: 'GET',
        params: data,
    });
};
export const readNotificationsApi = (data) => {
    return service({
        url: '/api/v1/me/notifications',
        method: 'PUT',
        data,
    });
};
export const deleteNotificationsApi = (data) => {
    return service({
        url: '/api/v1/me/notifications',
        method: 'DELETE',
        params: data,
    });
};

//leaves
export const getMyLeaveListApi = (data) => {
    return service({
        url: '/api/v1/me/leaves',
        method: 'GET',
        params: data,
    });
};
export const applyLeaveApi = (data) => {
    return service({
        url: '/api/v1/me/leaves',
        method: 'POST',
        data,
    });
};
export const getLeaveApi = (id) => {
    return service({
        url: `/api/v1/me/leaves/${id}`,
        method: 'GET',
    });
};
export const updateLeaveApi = (id, data) => {
    return service({
        url: `/api/v1/me/leaves/${id}`,
        method: 'PUT',
        data,
    });
};
// timecards
export const getTimecardListApi = (data) => {
    return service({
        url: '/api/v1/me/timecards',
        method: 'GET',
        params: data,
    });
};
export const createTimecardApi = (data) => {
    return service({
        url: '/api/v1/me/timecards',
        method: 'POST',
        data,
    });
};
export const getTimecardApprovalListApi = (data) => {
    return service({
        url: '/api/v1/me/timecard-approvals',
        method: 'GET',
        params: data,
    });
};
export const updateTimecardApprovalListApi = (data) => {
    return service({
        url: '/api/v1/me/timecard-approvals',
        method: 'PATCH',
        data,
    });
};
