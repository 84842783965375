import { ref, computed } from 'vue';
import moment from 'moment-timezone';
import { useMe } from '@/store/me';
export function useDate() {
    const meStore = useMe();

    const fullPeriod = ref([]);
    const startDate = ref('');
    const endDate = ref('')

    const weekdays = ref(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);

    //從指定的日期開始得到14天的日期+星期
    const getFullPeriod = (format = 'MM-DD-YYYY') => {
        fullPeriod.value = [];
        if (!startDate.value) {
            fullPeriod.value.length = 14;
            return;
        }
        for (let i = 0; i < 14; i++) {
            let date = moment(startDate.value, 'MM-DD-YYYY').add(i, 'days');
            const formattedDate = date.format(format);
            let dateString = `${formattedDate} ${weekdays.value[date.day()]}`;
            fullPeriod.value.push(dateString);
        }
    };

    const firstWeek = computed(() => {
        return fullPeriod.value.slice(0, 7);
    });
    const secondWeek = computed(() => {
        return fullPeriod.value.slice(7);
    });

    //將UTC+0轉換為entity的時區
    const getLocalTime = (datetime, type) => {
        if (datetime && meStore.timezone) {
            if (type === 'AM/PM') {
                return moment(datetime).tz(meStore.timezone).format('hh:mm A');
            }
            return moment(datetime).tz(meStore.timezone).format('HH:mm');
        }
        return '';
    };
    const getLocalDatetime = (datetime) => {
        if (datetime && meStore.timezone) {
            return moment(datetime).tz(meStore.timezone).format('MM-DD-YYYY HH:mm:ss');
        }
        return '';
    };

    //把美國日期改為標準日期
    const standardDateFormat = (date) => {
        return date ? moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD') : '';
    };

    //把標準日期改為美國日期
    const USDateFormat = (date) => {
        return date ? moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY') : '';
    };

    //將MM/DD/YYYY HH:mm:ss 轉為UTC+0
    const utcFromUSDatetime = (time) => {
        if (time) {
            const formatted = moment(time, 'MM-DD-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            return moment.tz(formatted, meStore.timezone).toISOString();
        }
        return '';
    };

    const getTimeDifference = (time) => {
        const now = moment(new Date());
        let diff = null;

        diff = now.diff(time, 'hours');
        if (diff < 1) {
            //小於一小時換算成分鐘
            diff = now.diff(time, 'minutes');
            if (diff === 0) {
                return 'Just now';
            }
            if (diff === 1) {
                return diff + ' min ago';
            }
            return diff + ' mins ago';
        }
        if (diff === 1) {
            return diff + ' hour ago';
        }

        if (diff > 1 && diff < 24) {
            return diff + ' hours ago';
        }
        if (diff >= 24) {
            diff = now.diff(time, 'days');
            if (diff <= 1) {
                return diff + ' day ago';
            }
            return diff + ' days ago';
        }
    };

    const getWeekDay = (date) => {
        if (date) {
            // return weekdays.value[new Date(date).getDay()];
            return weekdays.value[moment(date,'YYYY-MM-DD').day()];
        }
    };
    return {
        startDate,
        endDate,
        firstWeek,
        secondWeek,
        fullPeriod,
        weekdays,
        getLocalTime,
        getLocalDatetime,
        getFullPeriod,
        standardDateFormat,
        USDateFormat,
        utcFromUSDatetime,
        getTimeDifference,
        getWeekDay,
    };
}
