import { createRouter, createWebHistory } from 'vue-router';

import Dashboard from '../views/Dashboard.vue';
// import Dashboard from '../views/index.vue';

import { useSettings } from '@/store';
import { useAuth } from '@/store/auth';
import { useMe } from '@/store/me';
import { getPermissions } from '@/composables/getPermission';

const routes = [
    //dashboard
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            breadcrumb: [{ name: 'Dashboard' }],
        },
    },

    //pages
    {
        path: '/pages/coming-soon',
        name: 'coming-soon',
        component: () => import(/* webpackChunkName: "pages-coming-soon" */ '../views/pages/coming_soon.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/pages/error404',
        name: 'error404',
        component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/error404.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/pages/error500',
        name: 'error500',
        component: () => import(/* webpackChunkName: "pages-error500" */ '../views/pages/error500.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/pages/error503',
        name: 'error503',
        component: () => import(/* webpackChunkName: "pages-error503" */ '../views/pages/error503.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/pages/maintenence',
        name: 'maintenence',
        component: () => import(/* webpackChunkName: "pages-maintenence" */ '../views/pages/maintenence.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/redirect/:key',
        name: 'redirect',
        component: () => import(/* webpackChunkName: "pages-redirect" */ '../views/pages/Redirect.vue'),
        meta: { layout: 'auth' },
    },

    //auth
    {
        path: '/auth/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/Login.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/quick-punch',
        name: 'quick-punch',
        component: () => import(/* webpackChunkName: "auth-quick-punch" */ '../views/auth/QuickPunch.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth-register" */ '../views/auth/register.vue'),
        meta: { layout: 'auth' },
    },

    {
        path: '/auth/recover-password',
        name: 'recover-password',
        component: () => import(/* webpackChunkName: "auth-password-recovery" */ '../views/auth/RecoverPassword.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "auth-reset-password" */ '../views/auth/ResetPassword.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/email/verify',
        name: 'email-verify',
        component: () => import(/* webpackChunkName: "auth-email-verify" */ '../views/auth/EmailVerify.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/mobile/verify',
        name: 'mobile-verify',
        component: () => import(/* webpackChunkName: "auth-mobile-verify" */ '../views/auth/MobileVerify.vue'),
        meta: { layout: 'auth' },
    },

    //users
    {
        path: '/users/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/Profile.vue'),
        meta: {
            breadcrumb: [{ name: 'Users' }, { name: 'My Profile' }],
        },
    },
    {
        path: '/users/edit-profile',
        name: 'edit-profile',
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/profile/Edit.vue'),
        meta: {
            breadcrumb: [{ name: 'Users' }, { name: 'Edit Profile' }],
        },
    },
    {
        path: '/users/change-password',
        name: 'change-password',
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/ChangePassword.vue'),
        meta: {
            breadcrumb: [{ name: 'Users' }, { name: 'Change Password' }],
        },
    },
    {
        path: '/users/setting-pin-code',
        name: 'setting-pin-code',
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/PinCodeSetting.vue'),
        meta: {
            breadcrumb: [{ name: 'Users' }, { name: 'Setting PIN Code' }],
        },
    },
    {
        path: '/users/account-setting',
        name: 'account-setting',
        component: () => import(/* webpackChunkName: "users-account-setting" */ '../views/users/AccountSetting.vue'),
    },
    
    // onboard
    {
        path: '/onboard/submit-document',
        name: 'submit-document',
        component: () => import('../views/onboarding/DocumentSubmission.vue')
    },

    // employees
    {
        path: '/employees',
        component: () => import(/* webpackChunkName: "employees" */ '../views/management/employees/Index.vue'),
        children: [
            {
                path: '',
                name: 'employee',
                component: () => import(/* webpackChunkName: "employees" */ '../views/management/employees/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Employees' }, { name: 'Employees' }],
                },
            },
            {
                path: 'create',
                name: 'employee-create',
                component: () => import(/* webpackChunkName: "employees" */ '../views/management/employees/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Employees' }, { name: 'Add New Employee' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'employee-update',
                component: () => import(/* webpackChunkName: "employees" */ '../views/management/employees/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Employees' }, { name: 'Edit Employee' }],
                },
            },
            {
                path: 'view/:id',
                name: 'employee-view',
                component: () => import(/* webpackChunkName: "employees" */ '../views/management/employees/View.vue'),
                meta: {
                    breadcrumb: [{ name: 'Employees' }, { name: 'View Employee' }],
                },
            },
            {
                path: 'facial-recognition/:id',
                name: 'employee-facial-recognition',
                component: () => import(/* webpackChunkName: "employees" */ '../views/settings/FacialRecognition.vue'),
                meta: {
                    breadcrumb: [{ name: 'Employees' }, { name: 'Facial Recognition' }],
                },
            },
            {
                path: 'assign-permissions/:id',
                name: 'employee-assign-permissions',
                component: () => import(/* webpackChunkName: "employees" */ '../views/settings/AssignPermissions.vue'),
                meta: {
                    breadcrumb: [{ name: 'Employees' }, { name: 'Assign Permissions' }],
                },
            },
        ],
    },

    // notes
    {
        path: '/employees/notes',
        redirect: '/employees/notes',
        component: () => import(/* webpackChunkName: "employees" */ '../views/management/notes/Index.vue'),
        children: [
            {
                path: '',
                name: 'note',
                component: () => import(/* webpackChunkName: "employees" */ '../views/management/notes/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Employees' }, { name: 'Notes' }],
                },
            },
        ],
    },

    //employee timecards
    {
        path: '/employees/timecards',
        name: 'employee-timecard',
        component: () => import(/* webpackChunkName: "timecards" */ '../views/management/roster/Index.vue'),
        meta: {
            breadcrumb: [{ name: 'Employees' }, { name: 'Timecards' }],
        },
    },

    //employee leaves
    {
        path: '/employees/leaves',
        name: 'employee-leave',
        component: () => import(/* webpackChunkName: "timecards" */ '../views/employeeLeaves/LeaveList.vue'),
        meta: {
            breadcrumb: [{ name: 'Employees' }, { name: 'Leaves' }],
        },
    },

    // leaves
    {
        path: '/leaves',
        component: () => import(/* webpackChunkName: "leaves" */ '../views/leaves/Index.vue'),
        children: [
            {
                path: '',
                name: 'leave',
                component: () => import(/* webpackChunkName: "leaves" */ '../views/leaves/LeaveList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Leaves' }, { name: 'Leaves' }],
                },
            },
            {
                path: 'apply',
                name: 'leave-create',
                component: () => import(/* webpackChunkName: "leaves" */ '../views/leaves/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Leaves' }, { name: 'Apply Leave' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'leave-update',
                component: () => import(/* webpackChunkName: "leaves" */ '../views/leaves/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Leaves' }, { name: 'Edit Leave' }],
                },
            },
            {
                path: 'view/:id',
                name: 'leave-view',
                component: () => import(/* webpackChunkName: "leaves" */ '../views/leaves/View.vue'),
                meta: {
                    breadcrumb: [{ name: 'Leaves' }, { name: 'View Leave' }],
                },
            },
        ],
    },
    {
        path: '/leaves/approval',
        redirect: '/leaves/approval',
        component: () => import(/* webpackChunkName: "employees" */ '../views/approval/LeaveApproval.vue'),
        children: [
            {
                path: '',
                name: 'leave-approval',
                component: () => import(/* webpackChunkName: "employees" */ '../views/approval/Approval.vue'),
                meta: {
                    breadcrumb: [{ name: 'Employees' }, { name: 'Leave Approval' }],
                },
            },
        ],
    },

    //holiday calendar
    {
        path: '/leaves/holidays-calendar',
        name: 'holidays-calendar',
        component: () => import(/* webpackChunkName: "holidays-calendar" */ '../views/leaves/Holiday.vue'),
        meta: {
            breadcrumb: [{ name: 'Leaves' }, { name: 'Holidays Calendar' }],
        },
    },

    //timecards
    {
        path: '/attendance/timecards/:id?',
        component: () => import(/* webpackChunkName: "attendance-timecards" */ '../views/management/timecards/TimecardList.vue'),
        name: 'timecard',
        meta: {
            breadcrumb: [{ name: 'Attendance' }, { name: 'Timecards' }],
        },
    },
    {
        path: '/attendance/timecards/approval',
        redirect: '/attendance/timecards/approval',
        component: () => import(/* webpackChunkName: "employees" */ '../views/approval/TimecardApproval.vue'),
        children: [
            {
                path: '',
                name: 'timecard-approval',
                component: () => import(/* webpackChunkName: "employees" */ '../views/approval/Approval.vue'),
                meta: {
                    breadcrumb: [{ name: 'Attendance' }, { name: 'Timecard Approval' }],
                },
            },
        ],
    },
    // schedule
    {
        path: '/scheduling/schedule',
        name: 'schedule',
        component: () => import(/* webpackChunkName: "schedule" */ '../views/management/schedule/Schedule.vue'),
        meta: {
            breadcrumb: [{ name: 'Scheduling' }, { name: 'Schedule' }],
        },
    },

    //Payroll

    {
        path: '/payroll',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/payrolls/Index.vue'),
        children: [
            {
                path: '',
                name: 'payroll',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/payrolls/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Payroll' }, { name: 'Payroll' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'payroll-update',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/payrolls/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Payroll' }, { name: 'Edit Payroll' }],
                },
            },
        ],
    },
    
    // route for recruitment module 
    {
        path: '/recruitment',
        component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/Index.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'recruitment-dashboard',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/recruitments/Dashboard.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Dashboard' }],
                },
            },
            {
                path: 'time-to-hire',
                name: 'recruitment-time-to-hire',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/recruitments/TimeToHire.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Time-To-Hire' }],
                },
            },
            {
                path: 'approval-stages',
                name: 'recruitment-approval-stage',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/recruitments/approval-stages/Index.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Approval Stages' }],
                },
            },
            {
                path: 'reports',
                name: 'recruitment-reports',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/recruitments/reports/Reports.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Reports' }],
                },
            },
            {
                path: 'job-request',
                name: 'job-request',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-requests/List.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Requests' }],
                },
            },
            {
                path: 'job-request/create',
                name: 'job-request-create',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-requests/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Requests' }, { name: 'Add Job Request' }],
                },
            },
            {
                path: 'job-request/edit/:id',
                name: 'job-request-edit',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-requests/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Requests' }, { name: 'Edit Job Request' }],
                },
            },
            // {
            //     path: 'job-request/view/:id',
            //     name: 'job-request-view',
            //     component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-requests/Show.vue'),
            //     meta: {
            //         breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Requests' }, { name: 'View Job Request' }],
            //     },
            // },
            {
                path: 'job-opening',
                name: 'job-opening',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-openings/List.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Openings' }],
                },
            },
            {
                path: 'job-opening/create',
                name: 'job-opening-create',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-openings/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Openings' }, { name: 'Add Job Opening' }],
                },
            },
            {
                path: 'job-opening/new',
                name: 'job-opening-new',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-openings/New.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Openings' }, { name: 'Add Job Opening' }],
                },
            },
            {
                path: 'job-opening/edit/:id',
                name: 'job-opening-edit',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-openings/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Openings' }, { name: 'Edit Job Opening' }],
                },
            },
            {
                path: 'job-application',
                name: 'job-application',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-applications/List.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Applications' }],
                },
            },
            {
                path: 'job-application/:id',
                name: 'job-application-show',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-applications/Show.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Applications' }],
                },
            },
            {
                path: 'job-offer',
                name: 'job-offer',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-offers/List.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Offers' }],
                },
            },
            {
                path: 'job-offer/create',
                name: 'job-offer-create',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-offers/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Offers' }, { name: 'Add Job Offer' }],
                },
            },
            {
                path: 'job-offer/edit/:id',
                name: 'job-offer-edit',
                component: () => import(/* webpackChunkName: "recruitments" */ '../views/recruitments/job-offers/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Offers' }, { name: 'Edit Job Offer' }],
                },
            },
            {
                path: 'job-offer/view/:id',
                name: 'job-offer-view',
                component: () => import('../views/recruitments/job-offers/Show.vue'),
                meta: {
                    breadcrumb: [{ name: 'Recruitment' }, { name: 'Job Offers' }, { name: 'View Job Offer' }],
                },
            },
        ],
    },

    //Report
    {
        path: '/reports/standard-reports',
        component: () => import(/* webpackChunkName: "timecards" */ '../views/reports/Index.vue'),
        children: [
            {
                path: '',
                name: 'standard-report',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/reports/StandardReport.vue'),
                meta: {
                    breadcrumb: [{ name: 'Reports' }, { name: 'Standard Reports' }],
                },
            },
            {
                path: 'PDF',
                name: 'standard-report-PDF',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/reports/Preview.vue'),
                meta: {
                    breadcrumb: [{ name: 'Reports' }, { name: 'Standard Reports PDF' }],
                },
            },
        ],
    },
    //Direct Care Service
    {
        path: '/reports/direct-care-service',
        component: () => import(/* webpackChunkName: "timecards" */ '../views/reports/directCare/Index.vue'),
        children: [
            {
                path: '',
                name: 'direct-care-service',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/reports/directCare/DirectCareService.vue'),
                meta: {
                    breadcrumb: [{ name: 'Reports' }, { name: 'Direct Care Service' }],
                },
            },
        ],
    },

    // PBJ
    {
        path: '/payroll/payroll-based-journal',
        name: 'pbj',
        component: () => import(/* webpackChunkName: "schedule" */ '../views/payrolls/PBJ.vue'),
        meta: {
            breadcrumb: [{ name: 'Payroll' }, { name: 'Payroll Based Journal' }],
        },
    },

    //Settings

    //users
    {
        path: '/settings/users',
        component: () => import(/* webpackChunkName: "users" */ '../views/users/Index.vue'),
        children: [
            {
                path: '',
                name: 'user',
                component: () => import(/* webpackChunkName: "users" */ '../views/users/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Users' }, { name: 'Users' }],
                },
            },
            {
                path: 'create',
                name: 'user-create',
                component: () => import(/* webpackChunkName: "users" */ '../views/users/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Users' }, { name: 'Add New User' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'user-update',
                component: () => import(/* webpackChunkName: "users" */ '../views/users/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Users' }, { name: 'Edit User' }],
                },
            },
            {
                path: 'facial-recognition/:id',
                name: 'user-facial-recognition',
                component: () => import(/* webpackChunkName: "employees" */ '../views/settings/FacialRecognition.vue'),
                meta: {
                    breadcrumb: [{ name: 'Users' }, { name: 'Facial Recognition' }],
                },
            },
            {
                path: 'assign-permissions/:id',
                name: 'user-assign-permissions',
                component: () => import(/* webpackChunkName: "employees" */ '../views/settings/AssignPermissions.vue'),
                meta: {
                    breadcrumb: [{ name: 'Users' }, { name: 'Assign Permissions' }],
                },
            },
        ],
    },

    //departments
    {
        path: '/settings/departments',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/departments/Index.vue'),
        children: [
            {
                path: '',
                name: 'department',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/departments/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Departments' }],
                },
            },
        ],
    },

    //positions
    {
        path: '/settings/positions',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/positions/Index.vue'),
        children: [
            {
                path: '',
                name: 'position',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/positions/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Positions' }],
                },
            },
            {
                path: 'create',
                name: 'position-create',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/positions/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Add New Position' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'position-update',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/positions/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Edit Position' }],
                },
            },
        ],
    },

    // entities
    {
        path: '/settings/entities',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/entities/Index.vue'),
        children: [
            {
                path: '',
                name: 'entity',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/entities/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Entities' }],
                },
            },
            {
                path: 'create',
                name: 'entity-create',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/entities/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Add New Entity' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'entity-update',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/entities/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Edit Entity' }],
                },
            },
            // {
            //     path: 'view/:id',
            //     name: 'entity-view',
            //     component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/entities/View.vue'),
            //     meta: {
            //         breadcrumb: [{ name: 'Settings' }, { name: 'View Entity' }],
            //     },
            // },

            //punch machine
            {
                path: 'punch-machine/:id',
                name: 'punch-machine',
                component: () => import(/* webpackChunkName: "schedule" */ '../views/settings/entities/PunchMachine.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Punch Machine' }],
                },
            },
            {
                path: 'notification/:id',
                name: 'notification',
                component: () => import(/* webpackChunkName: "schedule" */ '../views/settings/entities/NotificationEvents.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Notification' }],
                },
            },
            {
                path: 'tiers-for-alert/:id',
                name: 'tiers-for-alert',
                component: () => import(/* webpackChunkName: "schedule" */ '../views/settings/entities/TiersForAlert.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Tiers for Alerts' }],
                },
            },
        ],
    },

    //roles
    {
        path: '/settings/roles',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/roles/Index.vue'),
        children: [
            {
                path: '',
                name: 'role',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/roles/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Roles' }],
                },
            },
            {
                path: 'create',
                name: 'role-create',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/roles/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Add New Role' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'role-update',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/roles/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Edit Role' }],
                },
            },
        ],
    },

    //payroll calendars
    {
        path: '/settings/payroll-calendars',
        redirect: '/settings/payroll-calendars',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/payrollCalendars/Index.vue'),
        children: [
            {
                path: '',
                name: 'payroll-calendar',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/payrollCalendars/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Payroll Calendars' }],
                },
            },
        ],
    },

    //agencies
    {
        path: '/settings/agencies',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/agencies/Index.vue'),
        children: [
            {
                path: '',
                name: 'agency',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/agencies/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Agencies' }],
                },
            },
        ],
    },

    //approval stages
    {
        path: '/settings/approval-stages',
        name: 'approval-stage',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/approvalStages/Index.vue'),
        meta: {
            breadcrumb: [{ name: 'Settings' }, { name: 'Approval Stages' }],
        },
    },
    //shifts
    {
        path: '/settings/shifts',
        component: () => import(/* webpackChunkName: "shifts" */ '../views/settings/shifts/Index.vue'),
        children: [
            {
                path: '',
                name: 'shift',
                component: () => import(/* webpackChunkName: "shifts" */ '../views/settings/shifts/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Shifts' }],
                },
            },
            {
                path: 'create',
                name: 'shift-create',
                component: () => import(/* webpackChunkName: "shifts" */ '../views/settings/shifts/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Add New Shift' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'shift-update',
                component: () => import(/* webpackChunkName: "shifts" */ '../views/settings/shifts/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Edit Shift' }],
                },
            },
        ],
    },
    //units
    {
        path: '/settings/units',
        component: () => import(/* webpackChunkName: "units" */ '../views/settings/units/Index.vue'),
        children: [
            {
                path: '',
                name: 'unit',
                component: () => import(/* webpackChunkName: "units" */ '../views/settings/units/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Units' }],
                },
            },
            {
                path: 'create',
                name: 'unit-create',
                component: () => import(/* webpackChunkName: "units" */ '../views/settings/units/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Add New Unit' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'unit-update',
                component: () => import(/* webpackChunkName: "units" */ '../views/settings/units/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Edit Unit' }],
                },
            },
        ],
    },
    //census
    {
        path: '/settings/census',
        component: () => import(/* webpackChunkName: "census" */ '../views/settings/census/Index.vue'),
        children: [
            {
                path: '',
                name: 'census',
                component: () => import(/* webpackChunkName: "census" */ '../views/settings/census/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Census' }],
                },
            },
            {
                path: 'create',
                name: 'census-create',
                component: () => import(/* webpackChunkName: "census" */ '../views/settings/census/Create.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Census' }],
                },
            },
            {
                path: 'edit/:id',
                name: 'census-update',
                component: () => import(/* webpackChunkName: "census" */ '../views/settings/census/Edit.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'Census' }],
                },
            },
        ],
    },
    //DHPPD
    {
        path: '/settings/dhppd-configuration',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/DHPPD/Index.vue'),
        children: [
            {
                path: '',
                name: 'DHPPD',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/DHPPD/CustomList.vue'),
                meta: {
                    breadcrumb: [{ name: 'Settings' }, { name: 'DHPPD' }],
                },
            },
        ],
    },

    //budget management
    {
        path: '/settings/budget-manage',
        name: 'budget-management',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/settings/budget/Index.vue'),
        meta: {
            breadcrumb: [{ name: 'Settings' }, { name: 'Budget Management' }],
        },
    },

    {
        path: '/job-post/:id/:slug',
        name: 'job-opening-post',
        component: () => import('../views/recruitments/job-openings/Show.vue'),
        meta: {
            layout: 'public',
        },
    },

    {
        path: '/applicants/register',
        name: 'JobApplicantRegister',
        component: () => import('../views/recruitments/applicants/Register.vue'),
        meta: {
            layout: 'public',
        },
    },

    {
        path: '/applicants/login',
        name: 'JobApplicantLogin',
        component: () => import('../views/recruitments/applicants/Login.vue'),
        meta: {
            layout: 'public',
        },
    },

    {
        path: '/applicants/recover',
        name: 'JobApplicantRecovery',
        component: () => import('../views/recruitments/applicants/RecoverPassword.vue'),
        meta: {
            layout: 'public',
        },
    },

    {
        path: '/applicants/reset',
        name: 'JobApplicantReset',
        component: () => import('../views/recruitments/applicants/ResetPassword.vue'),
        meta: {
            layout: 'public',
        },
    },

    {
        path: '/applicant/oauth/login',
        name: 'JobApplicantReset',
        component: () => import('../views/recruitments/applicants/OAuthLogin.vue'),
        meta: {
            layout: 'public',
        },
    },

    {
        path: '/applicant/profile',
        name: 'ApplicantProfile',
        component: () => import('../views/recruitments/applicants/profile/Profile.vue'),
        meta: {layout: 'public'},
    },

    {
        path: '/applicant/applications',
        name: 'ApplicantApplications',
        component: () => import('../views/recruitments/applicants/profile/Applications.vue'),
        meta: {layout: 'public'},
    },
    
    {
        path: '/applicant/offers',
        name: 'ApplicantOffers',
        component: () => import('../views/recruitments/applicants/profile/Offers.vue'),
        meta: {layout: 'public'},
    },
    
    {
        path: '/applicant/offers/:id',
        name: 'ApplicantViewOffer',
        component: () => import('../views/recruitments/applicants/profile/OfferShow.vue'),
        meta: {layout: 'public'},
    },
    
    {
        path: '/applicant/job-openings',
        name: 'ApplicantJobOpenings',
        component: () => import('../views/recruitments/applicants/JobOpenings.vue'),
        meta: {layout: 'public'},
    },
    
    {
        path: '/applicant/resume-upload',
        name: 'ApplicantResumeUpload',
        component: () => import('../views/recruitments/applicants/profile/UploadResume.vue'),
        meta: {layout: 'public'},
    },
    
    {
        path: '/:pathMatch(.*)*',
        redirect: '/pages/error404',
    },
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

const noAuthRoutes = [
    'dashboard',
    'job-opening-post',
    'login',
    'quick-punch',
    'register',
    'recover-password',
    'reset-password',
    'email-verify',
    'mobile-verify',
    'profile',
    'change-password',
    'setting-pin-code',
    'account-setting',
];

router.beforeEach(async (to, from, next) => {
    const settings = useSettings();
    const authStore = useAuth();
    const meStore = useMe();
    const isLoggedIn = JSON.parse($cookies.get('loggedIn'));
    const refreshToken = $cookies.get('refreshToken');
    const isPasswordExpired = JSON.parse($cookies.get('isPasswordExpired'));

    // If you are not logged in, you can only go to the login page.
    if ((to.meta.layout !== 'auth' && to.meta.layout !== 'public') && (!isLoggedIn || !refreshToken)) {
        authStore.clearAuth();
        next({ name: 'login' });
        return;
    }
    // Automatically redirect if password expires to change-password
    if (isPasswordExpired && to.name !== 'change-password' && to.name !== 'login' && to.name !== 'job-opening-post') {
        next({ name: 'change-password' });
        return;
    }
    if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
        settings.setLayout('auth')
    } else {
        settings.setLayout('app')
    }

    if (to.meta && to.meta.layout && to.meta.layout === 'public') {
        settings.setLayout('public')
    }
    
    // During reorganization or go to the page to obtain the URL permissions
    if (!from.name && authStore.refreshToken) {
        await getPermissions();
    }

    if (noAuthRoutes.includes(to.name)) {
        next();
        return;
    }

    next(true);
});

export default router;
