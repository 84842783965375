<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="">Copyright © {{currentYear}} <a target="_blank" href="https://www.u-ark.com.tw/">EVA CARE GROUP</a>, All rights reserved.</p>
        </div>
        <div class="footer-section f-section-2"></div>
    </div>
    <!-- END FOOTER -->
</template>

<script setup>
    import { ref, onMounted } from 'vue';

    const currentYear = ref(new Date().getFullYear());
</script>
