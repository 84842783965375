import {useAtsAuthStore} from "@/store/ats/auth";
import {useModal} from "@/store/modal";
import axios from "axios";
import {useToast} from "@/composables/functions";

const toast = useToast();
// const router = useRouter();
// const route = useRoute();

export function getApiBaseUrl() {
    return process.env.VUE_APP_BASE_URL;
}

export function getApplicantUrlPath(path) {
    return "/api/v1/applicant/" + path;
}

export function getApplicantUrl (path) {
    return getApiBaseUrl() + getApplicantUrlPath(path);
}

export default function (axiosConfig) {
    const authStore = useAtsAuthStore();
    const modalStore = useModal();

    const service = axios.create({
        headers: {
            'Content-Type': 'application/json'
        },
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: process.env.VUE_APP_REQUEST_TIMEOUT,
    });

    // Add a request interceptor
    service.interceptors.request.use(
        function (config) {
            // Do something before request is sent
            if (config.method !== 'get' && config.url !== '/api/v1/auth/refresh') {
                modalStore.setLoadingStatus(true);
            }
            const token = authStore.token;
            // 如果 token 存在的話，則帶入到 headers 當中
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return Promise.resolve(config);
        },
        function (error) {
            if (error.response.data.message) {
                toast.fire({
                    'text': error.response.data.message,
                    'icon': 'error'
                })
            }
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // // Add a response interceptor
    service.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data

            const { config } = response;
            if (config.method !== 'get') {
                modalStore.setLoadingStatus(false);
            }
            return Promise.resolve(response);
        },
        function (err) {            
            modalStore.setLoadingStatus(false);
            if (err.response.data.message) {
                useToast().fire({
                    'text': err.response.data.message,
                    'icon': 'error'
                })
            }
            return Promise.reject(err);
        }
    );
    return service(axiosConfig);
}
