import {ref} from "vue";
import {useRouter} from "vue-router";
import Swal from 'sweetalert2'
import {useAtsErrorStore} from "@/store/ats/error";

const router = useRouter()

const useToast = () => {
    return Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3600,
        // padding: '1.5em',
    });
}

const strCapitalize = (str) => {
    const strArray = str.split('')
    const firstChar = strArray.shift()
    return  firstChar.toUpperCase() + strArray.join('');
}

String.prototype.capitalize = strCapitalize

const confirmAlert = (callback, {message, successMessage, buttonText, fallback}, notify = true) => {
    const toast = useToast()
    new Swal({
        title: 'Are you sure?',
        text: message ?? "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: buttonText ?? 'Yes',
    }).then(async (result) => {
        if (result.value) {
            await callback();
            if (notify) {
                toast.fire({
                    icon: 'success',
                    title: successMessage ?? 'Done successfully',
                });
            }
            return;
        }
        fallback && fallback();
    });
}

const strSlug = (input) => {
    return input
    .toString()            // Ensure the input is a string
    .toLowerCase()         // Convert the string to lowercase
    .trim()                // Trim leading and trailing whitespaces
    .replace(/\s+/g, '-')  // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, '')  // Remove non-word characters (excluding hyphens)
    .replace(/\-\-+/g, '-');   // Replace consecutive hyphens with a single hyphen
}

const postLink = (item) => {
    const {id, title} = item;
    return window.location.origin+router.resolve({
        name: 'job-opening-post',
        params: { id, slug: strSlug(title) }
    }).href
}

const useQuillConfig = () => {
    return ref({
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ header: 1 }, { header: 2 }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['clean'],
            ]
        }
    })
}

const useFlatPickrConfig = (config = {}) => {
    const datePickerConfig = {
        dateFormat: config.dateFormat ?? 'm-d-Y',
        allowInput: config.allowInput ?? true,
        position: config.position ?? 'below',
    };
    delete config.dateFormat;
    delete config.allowInput;
    delete config.position;
    return ref({
        ...datePickerConfig, 
        ...config,
    })
}

const useFilePondConfig = () => {
    return {
        imagePreviewHeight: 170,
        imageCropAspectRatio: '1:1',
        imageResizeTargetWidth: 200,
        imageResizeTargetHeight: 200,
        stylePanelLayout: 'compact circle',
        styleLoadIndicatorPosition: 'center bottom',
        styleProgressIndicatorPosition: 'right bottom',
        styleButtonRemoveItemPosition: 'right top',
        styleButtonProcessItemPosition: 'right bottom',
    }
}

const setFormErrorsToStore = function (error) {
    const data = error?.response?.data ?? null;
    if (data?.status===1000) {
        const errors = {};
        Object.keys(data.result).map(function (key) {
            errors[key] = data.result[key][0];
        });
        useAtsErrorStore().setFormErrors(errors);
    }
}

export {
    setFormErrorsToStore,
    useFilePondConfig,
    useFlatPickrConfig,
    useQuillConfig,
    strCapitalize,
    confirmAlert,
    postLink,
    strSlug,
    useToast
}

export const validateForm = (formData, validator, rules) => {
    let isValid = true;
    Object.keys(formData).forEach(key => {
        validator[key] = true;
        if (formData[key] === "" || !formData[key]) {
            if (rules && rules[key]?.required) {
                validator[key] = false;
                isValid = false;
            } else {
                validator[key] = false;
                isValid = false;
            }
        }
    })
    return isValid;
}

export const toastNotify = {
    success: (successMessage) => {
        useToast().fire({
            icon: 'success',
            title: successMessage ?? 'Done successfully',
        });
    },
    error: (errorMessage) => {
        useToast().fire({
            icon: 'error',
            title: errorMessage ?? 'Something went wrong.',
        });
    }
}
