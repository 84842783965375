import service from '@/api/applicant/api'

export const applicantRegisterApi = async (data) => {
    return service({
        url: 'api/v1/applicant/register',
        method: 'POST',
        data: data
    });
}

export const applicantLoginApi = (data) => {
    return service({
        url: '/api/v1/applicant/login',
        method: 'POST',
        data
    });
};

export const applicantSocialLoginApi = (data) => {
    return service({
        url: '/api/v1/applicant/auth/login',
        method: 'POST',
        data
    });
};

export const applicantLogoutApi = () => {
    return service({
        url: '/api/v1/applicant/logout',
        method: 'POST',
    });
};

export const getApplicantApi = () => {
    return service({
        url: '/api/v1/applicant/user',
        method: 'GET',
    });
};

export const getApplicantNotificationsApi = (data) => {
    return service({
        url: '/api/v1/applicant/notifications',
        method: 'GET',
        params: data,
    });
};

export const readApplicantNotificationsApi = (data) => {
    return service({
        url: '/api/v1/applicant/notifications',
        method: 'PUT',
        data,
    });
};

export const applicantProfileUpdateApi = (data) => {
    return service({
        url: '/api/v1/applicant/update-profile',
        method: 'POST',
        data
    });
}

export const applicantApplyToJobApi = (data) => {
    return service({
        url: '/api/v1/applicant/apply-to-job',
        method: 'POST',
        data
    });
}


export const applicantCheckApplyApi = (data) => {
    return service({
        url: '/api/v1/applicant/check-apply',
        method: 'GET',
        params: data,
    });
}


export const getApplicantApplicationsApi = () => {
    return service({
        url: '/api/v1/applicant/applications',
        method: 'GET',
    });
};

export const getApplicantDownloadTokenApi = () => {
    return service({
        url: '/api/v1/applicant/get-download-token',
        method: 'POST',
    });
}

export const applicantJobOpeningsItemApi = (id) => {
    return service({
        url: '/api/v1/applicant/get-job-openings',
        method: 'GET',
    });
};

export const applicantGetEducationsApi = () => {
    return service({
        url: '/api/v1/applicant/education',
        method: 'GET',
    });
};

export const applicantAddEducationApi = (data) => {
    return service({
        url: '/api/v1/applicant/education',
        method: 'POST',
        data
    });
};

export const applicantUpdateEducationApi = (id, data) => {
    return service({
        url: '/api/v1/applicant/education/'+id,
        method: 'PUT',
        data
    });
};

export const applicantDeleteEducationApi = (id) => {
    return service({
        url: '/api/v1/applicant/education/'+id,
        method: 'DELETE',
    });
};

export const getApplicantOffersApi = () => {
    return service({
        url: '/api/v1/applicant/offers',
        method: 'GET',
    });
};

export const applicantAcceptOfferApi = (id, signature) => {
    return service({
        url: '/api/v1/applicant/offer/'+id+'/acceptance',
        method: 'PUT',
        data: {accepted: 1, signature}
    });
};

export const applicantRejectOfferApi = (id) => {
    return service({
        url: '/api/v1/applicant/offer/'+id+'/acceptance',
        method: 'PUT',
        data: {accepted: 2}
    });
};

export const getApplicantJobOfferInfoApi = (id) => {
    return service({
        url: '/api/v1/applicant/offer/'+id,
        method: 'GET'
    });
};

export const applicantOnboardApi = (data) => {
    return service({
        url: '/api/v1/applicant/onboard',
        method: 'POST',
        data: data,
    });
};
