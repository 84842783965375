<template>
    <div>
        <div class="header-wrapper">
            <!--  BEGIN NAVBAR  -->
            <div class="header-container fixed-top">
                <header class="header navbar navbar-expand-sm">
                    <ul class="navbar-item theme-brand flex-row text-center">
                        <li class="nav-item theme-logo">
                            <router-link to="/">
                                <img src="@/assets/images/logo-50.png" class="navbar-logo" alt="logo" />
                            </router-link>
                        </li>
                        <li class="nav-item theme-text">
                            <router-link to="/" class="nav-link"> EVA CARE GROUP </router-link>
                        </li>
                    </ul>

                    <div class="navbar-item flex-row ms-md-auto">
                        <router-link v-show="!atsAuthStore.checkAuth()" :to="{name: 'JobApplicantRegister'}" class="btn btn-outline-primary me-2"> Register </router-link>
                        <router-link v-show="!atsAuthStore.checkAuth()" :to="{name: 'JobApplicantLogin'}" class="btn btn-outline-primary me-2"> Login </router-link>
                        <Notification v-show="atsAuthStore.checkAuth()"/>
                        <TopRightDropdown v-show="atsAuthStore.checkAuth()"/>
                    </div>
                </header>
            </div>
        </div>
        
        <sub-header v-show="atsAuthStore.checkAuth() && $route.name !== 'job-opening-post'"/>
        
        <router-view></router-view>
        
        <div class="text-center">
            <p>Copyright © {{currentYear}} <a target="_blank" href="https://www.u-ark.com.tw/">EVA CARE GROUP</a>, All rights reserved.</p>
        </div>
    </div>
</template>

<script setup>
import { useSettings } from '@/store';
import {onMounted, ref} from "vue";
import Header from "@/components/layout/header.vue";
import {useAtsAuthStore} from "@/store/ats/auth";
import SubHeader from "@/views/recruitments/applicants/profile/SubHeader.vue";
import TopRightDropdown from "@/views/recruitments/applicants/layout/TopRightDropdown.vue";
import Notification from "@/views/recruitments/applicants/layout/Notification.vue";

const currentYear = ref(new Date().getFullYear());
const settings = useSettings();
const atsAuthStore = useAtsAuthStore();

onMounted(async () => {
    await atsAuthStore.getAuth()
})

</script>

<style scoped lang="scss">
</style>
