
import service from './api';

export const getCaptchaApi = (data) => {
    return service({
        url: '/api/v1/captcha',
        method: 'GET',
    });
};
export const loginApi = (data) => {
    return service({
        url: '/api/v1/auth/login',
        method: 'POST',
        data,
    });
};
export const sendMfaApi = (data) => {
    return service({
        url: '/api/v1/auth/mfa/send',
        method: 'POST',
        data,
    });
};
export const verifyMfaApi = (data) => {
    return service({
        url: '/api/v1/auth/mfa/verify',
        method: 'POST',
        data,
    });
};
export const logoutApi = () => {
    return service({
        url: '/api/v1/auth/logout',
        method: 'POST',
    });
};

export const forgotPasswordApi = (data) => {
    return service({
        url: '/api/v1/auth/password/forgot',
        method: 'POST',
        data,
    });
};

export const resetPasswordApi = (data) => {
    return service({
        url: '/api/v1/auth/password/reset',
        method: 'POST',
        data,
    });
};
export const sendMobileVerificationApi = () => {
    return service({
        url: '/api/v1/auth/cellphone/send',
        method: 'POST',
    });
};
export const sendEmailVerificationApi = () => {
    return service({
        url: '/api/v1/auth/email/send',
        method: 'POST',
    });
};

export const verifyMobileApi = (data) => {
    return service({
        url: '/api/v1/auth/cellphone/verify',
        method: 'GET',
        params: data,
    });
};
export const verifyEmailApi = (data) => {
    return service({
        url: '/api/v1/auth/email/verify',
        method: 'GET',
        params: data,
    });
};
export const refreshTokenApi = (data) => {
    return service({
        url: '/api/v1/auth/refresh',
        method: 'POST',
        data
    });
};
