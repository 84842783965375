import { getPermissionsApi } from '@/api/me';
import { useMe } from '@/store/me';

export const getPermissions = async () => {
    const meStore = useMe();
    const { data } = await getPermissionsApi();
    // const permissions = [];
    const customKeys = {
        'absence-and-leave': 'absence and leave',
        agency: 'agency',
        'approval-stage': 'approval stage',
        attendance: 'attendance',
        department: 'department',
        employee: 'employee',
        'employee-management': 'employee management',
        entity: 'entity',
        'holidays-calendar': 'holidays calendar',
        leave: 'leave',
        'leave-approval': 'leave approval',
        'employee-leave': 'employee leave',
        note: 'note',
        'payroll-calendar': 'payroll calendar',
        position: 'position',
        role: 'role',
        'employee-timecard': 'employee timecard',
        setting: 'setting',
        timecard: 'timecard',
        'timecard-approval': 'timecard approval',
        shift: 'shift',
        unit: 'unit',
        schedule: 'schedule',
        census: 'census',
        payroll: 'payroll',
        'payroll-based-journal': 'payroll based journal',
        user:'user',
        report:'report',
        'budget-management':'budget management',
        'dhppd-threshold':'dhppd threshold',
        'recruitment-approval-stage': 'recruitment approval stage',
        'job-request': 'recruitment job request',
        'job-opening': 'recruitment job opening',
        'job-application': 'recruitment job application',
        'job-offer': 'recruitment job offer',
    };
    // Object.keys(customKeys).forEach((key1) => {
    //     if (data.result[customKeys[key1]]) {
    //         Object.keys(data.result[customKeys[key1]]).forEach((key2) => {
    //             if (data.result[customKeys[key1]][key2]) {
    //                 if (key2 === 'view') {
    //                     permissions.push(`${key1}`);
    //                 } else {
    //                     permissions.push(`${key1}-${key2}`);
    //                 }
    //             }
    //         });
    //     }
    // });
    const permissions = Object.entries(customKeys).reduce((acc, [key1, displayKey1]) => {
        if (!data.result[displayKey1]) {
            return acc;
        }
        return [
            ...acc,
            ...Object.entries(data.result[displayKey1])
                .filter(([, value]) => value)
                .map(([key2, _]) => (key2 === 'view' ? key1 : `${key1}-${key2}`)),
        ];
    }, []);
    meStore.setPermissions(permissions);
};
