<script setup>
import {useAtsAuthStore} from "@/store/ats/auth";
import defaultAvatar from "@/assets/images/user-avtar.svg"

const authStore = useAtsAuthStore();

const handleLogout = () => {
    authStore.userLogout();
}

const getUserAvatar = () => {
    const user = authStore.getUser;
    if (user && user.image) {
        return user.image;
    }
    return defaultAvatar;
}
</script>

<template>
    <ul class="navbar-item flex-row ms-lg-auto ms-0 action-area">
        
        <li class="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="avatar-container">
                    <div class="avatar avatar-indicators avatar-online">
                        <img alt="avatar" :src="getUserAvatar()" class="rounded-circle border">
                    </div>
                </div>
            </a>

            <div class="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown" style="">
                <div class="dropdown-item">
                    <router-link :to="{name: 'ApplicantProfile'}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <span>Profile</span>
                    </router-link>
                </div>
                <div class="dropdown-item">
                    <a href="javascript:void(0)" @click="handleLogout">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> 
                        <span>Log Out</span>
                    </a>
                </div>
            </div>

        </li>
    </ul>
</template>

<style scoped lang="scss">

</style>
