import { defineStore } from 'pinia';
import { refreshTokenApi } from '@/api/auth';
import router from '@/router';
export const useAuth = defineStore('auth', {
    state: () => ({
        verifyMethod: 'email',
        hasEmail: false,
        hasCellphone: false,
        token: '',
        refreshToken: '',
        isRefreshing: false,
    }),
    actions: {
        setVerifyMethod(value) {
            this.verifyMethod = value;
        },
        setToken(token) {
            this.token = token;
        },
        setRefreshToken(token) {
            this.refreshToken = token || null;
        },
        setAvailable(email, phone) {
            this.hasEmail = email;
            this.hasCellphone = phone;
        },
        clearAuth() {
            $cookies.remove('loggedIn');
            $cookies.remove('refreshToken');
            $cookies.remove('token');
            $cookies.remove('isPasswordExpired');
            this.setToken('');
            this.setRefreshToken('');
            router.push({ name: 'JobApplicantLogin' });
        },
        async dispatchRefresh() {
            try {
                if (!$cookies.get('refreshToken')) {
                    this.clearAuth();
                    return;
                }
                const { data } = await refreshTokenApi({ refresh_token: this.refreshToken });
                const identity = data.result.identity;
                if (identity) {
                    $cookies.set('token', identity.access_token, `${identity.expires_in - 90}s`);
                    $cookies.set('refreshToken', identity.refresh_token, '3600s');
                    this.setToken(identity.access_token);
                    this.setRefreshToken(identity.refresh_token);
                }
            } catch (e) {
                this.clearAuth();
            }
        },
        setRefreshingStatus(val) {
            this.isRefreshing = val;
        },
    },
    getters: {},
});
