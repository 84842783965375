import { defineStore } from 'pinia';
import router from '@/router';
import {
    applicantLoginApi,
    applicantLogoutApi,
    applicantRegisterApi,
    applicantSocialLoginApi,
    getApplicantApi
} from "@/api/applicant";
import {setFormErrorsToStore, useToast} from "@/composables/functions";

export const useAtsAuthStore = defineStore('atsAuth', {
    state: () => ({
        user: null,
        token: null,
        isLoggedIn: null,
    }),
    actions: {
        getToken() {
            return this.token;
        },
        setToken(token) {
            $cookies.set('ats.token', token, '7200s');
            this.token = token;
        },
        setUser(user) {
            $cookies.set('ats.user', user, '7200s');
            this.user = user;
        },
        setLoggedIn(status) {
            $cookies.set('ats.isLoggedIn', status, '7200s');
            this.isLoggedIn = status;
        },
        getAuthToken() {
            return $cookies.get('ats.token');
        },
        async userRegister(data) {
            try {
                applicantRegisterApi(data).then(async (res1) => {
                    if (res1.data.result.token) {
                        this.setToken(res1.data.result.token);
                        const res = await getApplicantApi();
                        if (res.data.result) {
                            this.setUser(res.data.result);
                            this.setLoggedIn(true);
                            
                            useToast().fire({
                                'icon': 'success',
                                'title': 'Registration Successful',
                                'text': 'A verification email has been sent to your email.'
                            })

                            await router.push({name: 'ApplicantResumeUpload'})
                        }
                    }
                }).catch(setFormErrorsToStore)
            } catch (error) {
                console.error(error)                
            }
        },
        async userLogin(data) {
            const response = await applicantLoginApi(data)
                .catch(setFormErrorsToStore);
            const result = response?.data?.result;
            if (result?.token) {
                this.setToken(result.token);
                const res = await getApplicantApi();
                if (res.data) {
                    this.setUser(res.data.result);
                    this.setLoggedIn(true);
                    if(!this.user.resume||this.user.resume==='') {
                        await router.push({name: 'ApplicantResumeUpload'});
                    } else if (!this.user.is_profile_complete) {
                        await router.push({name: 'ApplicantProfile'})
                    } else {
                        await router.push({name: 'ApplicantApplications'})
                    }
                }
            }
        },
        async userLogout() {
            await applicantLogoutApi();
            this.clearAuth();
            await router.push({name: 'JobApplicantLogin'});
        },
        async socialLogin(query) {
            const response = await applicantSocialLoginApi(query)
            .catch(setFormErrorsToStore);
            const result = response?.data?.result;
            if (result?.token) {
                this.setToken(result.token);
                const res = await getApplicantApi();
                if (res.data) {
                    this.setUser(res.data.result);
                    this.setLoggedIn(true);
                    if(!this.user.resume||this.user.resume==='') {
                        await router.push({name: 'ApplicantResumeUpload'});
                    } else if (!this.user.is_profile_complete) {
                        await router.push({name: 'ApplicantProfile'})
                    } else {
                        await router.push({name: 'ApplicantApplications'})
                    }
                }
            }
        },
        clearAuth() {
            this.setToken(null);
            this.setUser(null);
            this.setLoggedIn(false);
            $cookies.remove('ats.isLoggedIn');
            $cookies.remove('ats.token');
            $cookies.remove('ats.user');
        },
        checkAuth() {
            return $cookies.get('ats.isLoggedIn') || this.isLoggedIn;
        },
        async getAuth() {
            const authToken = this.getAuthToken();
            if (authToken) {
                this.setToken(authToken)
                await getApplicantApi().then((res) => {
                    if (res.data.result) {
                        this.setUser(res.data.result);
                        this.setLoggedIn(true);
                    } else {
                        this.clearAuth();
                    }
                });
            }
        },
        async getUserData() {
            return await getApplicantApi().then((res) => {
                if (res.data.result) {
                    return res.data.result;
                }
                return null;
            });
        },
        getUserCookie() {
            return $cookies.get('ats.user');
        }
    },
    getters: {
        getUser(state) {
            return state.user;
        },
    },
});
