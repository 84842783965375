import axios from 'axios';
import {useAuth} from '@/store/auth';
import {useModal} from '@/store/modal';

let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRefreshed(token) {
    refreshSubscribers.map((cb) => cb(token));
}

export default function (axiosConfig) {
    const authStore = useAuth();
    const modalStore = useModal();

    const service = axios.create({
        headers: {
            'Content-Type': 'application/json',
        },
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: process.env.VUE_APP_REQUEST_TIMEOUT,
    });

    // Add a request interceptor
    service.interceptors.request.use(
        async function (config) {
            // Do something before request is sent
            if (config.method !== 'get' && config.url !== '/api/v1/auth/refresh') {
                modalStore.setLoadingStatus(true);
            }
            const token = authStore.token;
            // 如果 token 存在的話，則帶入到 headers 當中
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // // Add a response interceptor
    service.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data

            const { config } = response;
            if (config.method !== 'get') {
                modalStore.setLoadingStatus(false);
            }
            return response;
        },
        async function (err) {
            const {config, response: { status }} = err;

            if (status === 401) {
                const isRefreshing = authStore.isRefreshing;
                if (!isRefreshing) {
                    authStore.setRefreshingStatus(true);
                    await authStore.dispatchRefresh();
                    authStore.setRefreshingStatus(false);
                    const token = authStore.token;
                    onRefreshed(token);
                    setTimeout(() => {
                        refreshSubscribers = [];
                    }, 3000);
                }
                if (config.method === 'get') {
                    return new Promise((resolve, reject) => {
                        subscribeTokenRefresh((token) => {
                            // replace the expired token and retry
                            // originalRequest.headers['Authorization'] = 'Bearer ' + token;

                            resolve(axios({...config, headers: {...config.headers, Authorization: `Bearer ${token}`}}));
                        });
                    });
                } else {
                    try {
                        const token = authStore.token;
                        return await axios({
                            ...config,
                            headers: {
                                ...config.headers,
                                Authorization: `Bearer ${token}`,
                            },
                        });
                    } catch (error) {
                        return Promise.reject(error);
                    }
                }
            }
            modalStore.setLoadingStatus(false);
            return Promise.reject(err);
        }
    );
    return service(axiosConfig);
}
