import { defineStore } from 'pinia';

export const useMe = defineStore('me', {
    state: () => ({
        permissions: [],
        menuList: [],
        meInfo: {},
        isPasswordExpired: false,
        jobCodes: [],
    }),
    actions: {
        setPermissions(value) {
            this.permissions = value;
        },

        setMenuList(value) {
            this.menuList = value;
        },
        setPasswordExpiration(value) {
            this.isPasswordExpired = value;
        },
        setMeInfo(value) {
            this.meInfo = value;
        },
        setMeJobCodes(value) {
            this.jobCodes = value || [];
        },
    },
    getters: {
        timezone: (getters) => getters.entity.timezone || '',
        firstName: (state) => state.meInfo.first_name,
        lastName: (state) => state.meInfo.last_name,
        fullName: (state) => state.meInfo.last_name + ', ' + state.meInfo.first_name,
        entity: (state) => state.meInfo.latest_login_entity || {},
        avatar: (state) => state.meInfo.avatar,
    },
});
