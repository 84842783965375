import { defineStore } from 'pinia';

export const useAtsErrorStore = defineStore('atsErrors', {
    state: () => ({
        formErrors: {},
    }),
    actions: {
        setFormErrors(errors) {
            this.formErrors = errors;
        },
        resetFormErrors() {
            this.formErrors = {};
        },
    },
    getters: {
        getFormErrors(state) {
            return state.formErrors;
        },
    },
});
