<template>
    <div @mousemove="refresh()" :class="[settings.layout_style, settings.menu_style]">
        <component v-bind:is="layout"></component>
        <CookieModal />
        <LoadingModal />
        <CustomModal v-if="modalStore.general" />
    </div>
</template>
<script setup>
    import { computed, onMounted } from 'vue';

    // import '@/assets/sass/elements/tooltip.scss';
    import '@/assets/sass/font-icons/fontawesome/css/regular.css';
    import '@/assets/sass/font-icons/fontawesome/css/fontawesome.css';
    import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
    import '@/assets/sass/app.scss';

    import CookieModal from '@/components/modals/CookieModal.vue';
    import LoadingModal from '@/components/modals/LoadingModal.vue';
    import CustomModal from '@/components/modals/CustomModal.vue';

    import { useMeta } from '@/composables/use-meta';
    import { useSettings } from './store';
    import { useAuth } from './store/auth';
    import { useModal } from './store/modal';

    const modalStore = useModal();

    const settings = useSettings();
    const authStore = useAuth();

    useMeta({ title: 'Eva Care Payroll' });

    onMounted(() => {});

    const layout = computed(() => {
        return settings.getLayout;
    });

    authStore.setToken($cookies.get('token'));
    authStore.setRefreshToken($cookies.get('refreshToken'));

    const refresh = async () => {
        const token = $cookies.get('token');
        const refreshToken = $cookies.get('refreshToken');
        if (!refreshToken) {
            return;
        }
        if (!authStore.isRefreshing && !token) {
            authStore.setRefreshingStatus(true);
            await authStore.dispatchRefresh();
            authStore.setRefreshingStatus(false);
        }
    };
</script>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';
    import publicLayout from './layouts/public-layout.vue';

    export default {
        components: {
            app: appLayout,
            auth: authLayout,
            public: publicLayout,
        },
    };
</script>
