import {createApp} from 'vue';
import App from './App.vue';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
import router from './router';
import {createPinia} from 'pinia';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import {saveAs} from 'file-saver';

const app = createApp(App);

app.config.errorHandler = function (err, vm, info) {
    console.error(err);
};

app.config.warnHandler = function (err, vm, trace) {
    console.error(err, trace);
};

app.config.productionTip = false;

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            //   tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
    ],
    tracesSampleRate: 1.0,
    trackComponents: true,
});

// bootstrap
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import {createHead} from '@vueuse/head';

const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';

window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import {registerScrollSpy} from 'vue3-scroll-spy/dist/index';

registerScrollSpy(app, {offset: 118});

//vue-i18n
import i18n from './i18n';

// datatables
import {ClientTable, ServerTable} from 'v-tables-3';

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

// set default settings
import appSetting from './app-setting';

window.$appSetting = appSetting;

// fontawesome
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import {useToast} from './composables/functions'

const toast = useToast();

const initTooltip = () => {
    setTimeout(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map((tooltipTriggerEl) => {
            return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    });
};

app.config.globalProperties.$cookies = VueCookies;
app.provide('toast', toast);
app.provide('initTooltip', initTooltip);
// app.provide('saveAs', saveAs);
//flatpickr
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
// import '@/assets/sass/forms/custom-flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';

// multiselect
import Multiselect from '@suadelabs/vue3-multiselect';
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css'

app.config.globalProperties.$cookies = VueCookies;
app.component("flatPickr", flatPickr);
app.component("Multiselect", Multiselect);
app.component("v-select", VueSelect);

import VueUploadComponent from 'vue-upload-component'
app.component('file-upload', VueUploadComponent)

// filters
app.config.globalProperties.$filters = {
    capitalizeLetter(string) {
        return string
        .split(' ')
        .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
        .join(' ');
    },
};

import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts);

const pinia = createPinia();

app.provide('pinia', pinia)

app.use(VueCookies)
.use(pinia)
.use(VueAxios, axios)
.use(router)
.use(i18n)
.use(PerfectScrollbar)
.use(VueNouislider)
.use(Maska)
.use(ClientTable)
.use(ServerTable)
.use(VueTelInput)
.use(vue3JsonExcel)
.use(VueFormWizard)
.use(head)
.mount('#app');

window.$appSetting.init();
