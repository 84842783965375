<template>
    <teleport to="body">
        <div v-if="modalStore.cookieShow" class="backdrop">
            <div class="cookie-modal">
                <div class="title">
                    <h5>This site uses cookies</h5>
                    <!-- <router-link class="setting-mobile" to="">Settings <img class="chevron-right" src="@/assets/icons/chevron-right.svg" alt="" /></router-link> -->
                </div>
                <div class="content">
                    <p>
                        Cookies are used to enhance browsing and personalize your experience. By continuing you are consenting to the use of cookies. To learn more or adjust your settings visit the
                        cookie preferences page.
                    </p>
                    <!-- <router-link class="setting-pc" to="">Settings <img class="chevron-right" src="@/assets/icons/chevron-right.svg" alt="" /></router-link> -->
                </div>
                <div class="footer">
                    <button @click="closeCookieModal()" class="cookie-button allow-all">Allow all cookies</button>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script setup>
    import { useModal } from '@/store/modal';
    const modalStore = useModal();

    const closeCookieModal = () =>{
        $cookies.set('allowCookie',true,Infinity)
        modalStore.closeCookieModal()
    }
</script>

<style lang="scss" scoped>
    .backdrop {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.23);
        .cookie-modal {
            display: flex;
            flex-direction: column;
            padding: 20px 0 3px 0;
            background: #fff;
            width: 76%;
            box-shadow: 0 0 10px #000;
            margin-bottom: 10px;
            .chevron-right {
                width: 18px;
            }
            a {
                color: $primary;
                font-weight: bold;
            }
            .title {
                padding: 0 10px 0 20px;

                h5 {
                    font-weight: bold;
                    color: #000;
                    font-size: 14px;
                    margin: 0;
                }
                .setting-mobile {
                    display: none;
                }
            }
            .content {
                padding: 0 10px 0 20px;
                display: flex;
                justify-content: space-between;
                p {
                    font-weight: bold;
                    font-size: 13px;
                    color: rgb(79, 75, 75);
                }
            }
            .footer {
                display: flex;
                justify-content: flex-end;
                padding-right: 10px;
                .cookie-button {
                    width: 33%;
                    border: 1px solid #000;
                    color: #000;
                    background: #fff;
                    font-weight: bold;
                    height: 35px;
                }
                .allow-all {
                    background: #000;
                    color: #fff;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .backdrop {
            align-items: center;
            .cookie-modal {
                padding-top: 15px;
                .title {
                    display: flex;
                    justify-content: space-between;

                    .setting-mobile {
                        display: block;
                        margin-top: -10px;
                    }
                }
                .content {
                    padding-bottom: 12px;
                    border-bottom: 1px solid rgba(206, 204, 204, 0.865);
                    p {
                        width: 100%;
                    }
                    .setting-pc {
                        display: none;
                    }
                }
                .footer {
                    flex-direction: column;
                    margin-top: 5px;
                    padding: 0 3px;
                    .cookie-button {
                        width: 100%;
                    }
                }
            }
        }
    }
</style>
