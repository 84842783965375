<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <li class="menu" v-if="meStore.meInfo.onboarding">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#onboarding" aria-controls="onboarding" aria-expanded="false">
                        <div class="">
                            <i class="fa-solid fa-chevron-circle-right"></i>
                            <span>Onboard</span>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
                                class="feather feather-chevron-right">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <ul id="onboarding" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link :to="{name: 'edit-profile'}" @click="toggleMobileMenu"> Edit Profile </router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'submit-document'}" @click="toggleMobileMenu"> Submit Document </router-link>
                        </li>
                    </ul>
                </li>
                <li ref="cate" v-for="menu in meStore.menuList" :key="menu.name" class="menu">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" :data-bs-target="'#' + menuName(menu.name)" :aria-controls="menuName(menu.name)" aria-expanded="false">
                        <div class="">
                            <i class="fa-solid" :class="'fa-' + menu.icon"></i>
                            <span>{{ menu.name }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <ul :id="menuName(menu.name)" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li v-for="child in menu.children" :key="child.name">
                            <router-link :to="child.url" @click="toggleMobileMenu"> {{ child.name }} </router-link>
                        </li>
                    </ul>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
    import { useSettings } from '@/store';
    import { useMe } from '@/store/me';
    import { useAuth } from '@/store/auth';
    import { onMounted, ref } from 'vue';
    import { getMenuListApi } from '@/api/me';
    import { useRoute } from 'vue-router';

    const settings = useSettings();
    const meStore = useMe();
    const authStore = useAuth();
    const route = useRoute();

    const menu_collapse = ref('dashboard');

    const cate = ref(null);

    onMounted(async () => {
        if (authStore.refreshToken) {
            await getMenuList();
        }
    });
    const menuName = (name) => {
        return name.split(' ').join('_').replace('&', '');
    };

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            settings.toggleSideBar(!settings.is_show_sidebar);
        }
    };

    const getMenuList = async () => {
        const { data } = await getMenuListApi();
        meStore.setMenuList(data.result);
        openSidebar();
    };

    const openSidebar = () => {
        setTimeout(() => {
            const active = document.querySelector('.router-link-active');
            if (active) {
                const ul = active.closest('ul.collapse');
                if (ul) {
                    let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                    if (ele) {
                        ele = ele[0];
                        setTimeout(() => {
                            ele.click();
                        });
                    }
                }
            }
        }, 300);
    };
</script>
