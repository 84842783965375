<template>
    <teleport to="body">
        <Transition name="nested">
            <div v-if="modalStore.modalShow" class="custom-modal" @click.self="selfCloseModal">
                <div class="modal-container inner" :class="{ 'modal-container-lg': modalStore.size === 'large' }">
                    <div class="custom-modal-header" v-if="modalStore.header">
                        <slot name="title">
                            <h4 class="modal-title">{{ modalStore.modalTitle }}</h4></slot
                        >
                        <slot name="x-mark">
                            <button type="button" @click="closeModal()" class="btn-close" aria-label="Close"></button>
                        </slot>
                    </div>
                    <div :class="{ 'overflow-visible': modalStore.overflow === 'visible' }" class="custom-modal-body">
                        <slot name="content"
                            >Some default textSome default textSome default textSome default textSome default textSome default textSome default textSome default textSome default textSome default
                            textSome default textSome default textSome default textSome default textSome default textSome default textSome default textSome default text</slot
                        >
                    </div>
                    <div class="custom-modal-footer">
                        <slot name="footer">
                            <button @click="closeModal" class="btn btn-outline-primary">
                                <slot name="close-modal">Discard </slot>
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </Transition>
    </teleport>
</template>

<script setup>
    import { useModal } from '@/store/modal';
    import { onMounted, ref } from 'vue';
    import { useToggleModal } from '@/composables/toggleModal';

    const { closeModal } = useToggleModal();
    const selfClose = ref(true);
    const modalStore = useModal();
    const selfCloseModal = (event) => {
        if (modalStore.selfClose) {
            closeModal();
        }
    };
</script>

<style lang="scss" scoped>
    .custom-modal {
        z-index: 9999;
        background: rgba(81, 83, 101, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .modal-container {
            width: 510px;
            background: #fff;
            border-radius: 8px;
            .custom-modal-header {
                padding: 15px 15px 10px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #e0e6ed;
                h3 {
                    margin: 0;
                    font-weight: bold;
                }
                .close-btn {
                    padding: 0;
                    width: auto;
                    height: auto;
                    background: none;
                    border: none;
                }
            }
            .custom-modal-body {
                min-height: 150px;
                max-height: 60vh;
                overflow: auto;
                padding: 15px;
                font-size: 16px;
            }
            .overflow-visible {
                overflow: visible;
            }
            .custom-modal-footer {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                gap: 8px 0;
                min-height: 30px;
                border-top: 1px solid #e0e6ed;
                padding: 15px;
                button {
                    width: 100px;
                    height: 30px;
                    font-weight: normal;
                }
            }
        }
        .modal-container-lg {
            width: 1200px;
        }
    }
    :deep(p) {
        color: $m-color_6;
    }
    @media screen and (max-width: 1224px) {
        .modal-container-lg {
            max-width: 900px;
        }
    }
    @media screen and (max-width: 991px) {
        .modal-container-lg {
            max-width: 760px;
        }
    }
    @media screen and (max-width: 768px) {
        .modal-container {
            max-width: 92%;
        }
        .modal-container-lg {
            max-width: 85%;
        }
    }

    .nested-enter-active,
    .nested-leave-active {
        transition: all 0.6s ease;
    }

    .nested-enter-from,
    .nested-leave-to {
        opacity: 0;
    }

    .nested-enter-active .inner,
    .nested-leave-active .inner {
        transition: all 0.5s ease;
    }

    .nested-enter-from .inner {
        transform: translateY(-25%);
    }
    .nested-leave-to .inner {
        transform: translateY(-25%);
    }

    .btn {
        font-weight: bold;
    }
    .discard {
        background: #fff;
        color: $primary;
        margin: 0 15px;
        border: 1px solid #e8e8e8;
    }
    .dark {
        .modal-container {
            background: #060818;
        }
    }
</style>
