<script setup>
import Header from "@/components/layout/header.vue";
const {settings} = defineProps(['settings'])
</script>

<template>
    <div class="sub-header-container">
        <header class="header navbar navbar-expand-sm">
            <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="settings.toggleSideBar(!settings.is_show_sidebar)">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-menu"
                >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
            </a>

            <!-- Portal vue/Teleport for Breadcrumb -->
            <div id="breadcrumb" class="vue-portal-target">
                <ul class="navbar-nav flex-row">
                    <li>
                        <div class="page-header">
                            <nav class="breadcrumb-one" aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                        <!--                                            <li v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item active">-->
                                        <!--                                                <a href="javascript:;">{{ crumb.name }}</a>-->
                                        <!--                                                <span v-if="index < breadcrumbs.length - 1" class="breadcrumb-slash">/</span>-->
                                        <!--                                            </li>-->
                                </ol>
                            </nav>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    </div>
</template>

<style scoped lang="scss">

</style>
