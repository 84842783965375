import { ref } from 'vue';
import { useModal } from '@/store/modal';
export function useToggleModal() {
    const modalStore = useModal();

    const body = document.querySelector('body');

    const modalConfig = ref({
        title: 'Modal Title',
        message:
            'As we can see, the core logic remains identical - all we had to do was move it into an external function and return the state that should be exposed. Just like inside a component, you can use the full range of Composition API functions in composables. The same useMouse() functionality can now be used in any component.',
        name: '',
        selfClose: true,
        size: '',
        header: true, // 預設顯示header標題
    });

    function openModal(config) {
        modalStore.setModalTitle(config.title || '');
        modalStore.setModalMessage(config.message || '');
        modalStore.openModal();
        modalStore.setGeneral();
        modalStore.setSelfClose(config.selfClose ?? true);
        modalStore.setSize(config.size);
        modalStore.setHeader(config.header ?? true);
        body.style.overflow = 'hidden';
    }
    function openPortalModal(config) {
        modalStore.openModal();
        modalStore.setModalTitle(config.title || '');
        modalStore.setModalName(config.name);
        modalStore.setSelfClose(config.selfClose ?? true);
        modalStore.setSize(config.size);
        modalStore.setHeader(config.header ?? true);
        modalStore.setOverflow(config.overflow || 'auto');
        modalStore.setModalData(config.data || {});

        body.style.overflow = 'hidden';
    }
    function closeModal() {
        modalStore.closeModal();
        body.style.overflow = 'auto';
    }
    return {
        openModal,
        openPortalModal,
        closeModal,
        modalConfig,
    };
}
