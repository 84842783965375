<template>
    <teleport to="body">
        <div v-if="modalStore.loading" class="backdrop">
            <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                <div class="spinner-border text-success align-self-center">Loading...</div>
            </div>
        </div>
    </teleport>
</template>

<script setup>
    import { useModal } from '@/store/modal';
    const modalStore = useModal();
</script>

<style lang="scss" scoped>
    .backdrop {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.13);
        backdrop-filter: blur(2px);
    }
</style>
