import { defineStore } from 'pinia';

export const useModal = defineStore('modal', {
    state: () => ({
        modalShow: false,
        cookieShow: false,
        modalTitle: '',
        modalMessage: '',
        selfClose: true,
        general: false,
        modalName: '',
        size: '',
        header: false,
        overflow: 'auto',
        data: {},
        loading: false,
    }),
    actions: {
        openModal() {
            setTimeout(() => {
                this.modalShow = true;
            }, 200);
        },
        closeModal() {
            this.modalShow = false;
            this.modalType = '';
            this.modalTitle = '';
            this.modalMessage = '';
            this.size = '';
            this.data = {};
            setTimeout(() => {
                this.general = false;
            }, 300);
            setTimeout(() => {
                this.modalName = '';
            }, 300);
        },
        openCookieModal() {
            this.cookieShow = true;
        },
        closeCookieModal() {
            this.cookieShow = false;
        },
        setModalTitle(value) {
            this.modalTitle = value;
        },
        setModalMessage(value) {
            this.modalMessage = value;
        },
        setModalData(value) {
            this.data = value;
        },
        setGeneral() {
            this.general = true;
        },
        setModalName(val) {
            this.modalName = val;
        },
        setSelfClose(val) {
            this.selfClose = val;
        },
        setSize(val) {
            this.size = val;
        },
        setHeader(val) {
            this.header = val;
        },
        setOverflow(val) {
            this.overflow = val;
        },
        setLoadingStatus(val) {
            this.loading = val;
        },
    },
    getters: {},
});
