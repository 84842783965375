<template>
    <div class="header-wrapper">
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo">
                        <router-link to="/">
                            <img src="@/assets/images/logo-50.png" class="navbar-logo" alt="logo" />
                        </router-link>
                    </li>
                    <li class="nav-item theme-text">
                        <router-link to="/" class="nav-link"> EVA CARE GROUP </router-link>
                    </li>
                </ul>
                <div class="d-none horizontal-menu">
                    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="settings.toggleSideBar(!settings.is_show_sidebar)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div>

                <div ref="entityOptionsRef" class="navbar-item flex-row ms-md-auto">
                    <div @click="toggleEntityDropdown()" :class="{ 'single-entity': entityList.length === 1 }" class="entity-select">
                        <span>
                            <i class="fa-solid fa-building"></i>
                        </span>
                        <p class="entity-name" v-if="meEntity.code">{{ meEntity.code }}</p>
                        <span v-if="entityList.length > 1" class="arrow" :class="{ 'arrow-up': showEntityDropdown }">
                            <i class="fa-solid fa-chevron-down"></i>
                        </span>
                    </div>
                    <Transition name="fade">
                        <ul v-show="showEntityDropdown" class="entities">
                            <perfect-scrollbar>
                                <li
                                    @click="changeEntity(entity)"
                                    class="entity"
                                    :class="{ 'entity-chosen': entity.code === meEntity.code }"
                                    v-for="entity in entityList"
                                    :key="entity.id"
                                    role="presentation"
                                >
                                    <i class="fa-solid fa-circle"></i>
                                    <h6>
                                        {{ entity.code }}
                                    </h6>
                                </li>
                            </perfect-scrollbar>
                        </ul>
                    </Transition>

                    <div class="dark-mode d-flex align-items-center">
                        <a v-if="settings.dark_mode == 'light'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('dark')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-sun"
                            >
                                <circle cx="12" cy="12" r="5"></circle>
                                <line x1="12" y1="1" x2="12" y2="3"></line>
                                <line x1="12" y1="21" x2="12" y2="23"></line>
                                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                <line x1="1" y1="12" x2="3" y2="12"></line>
                                <line x1="21" y1="12" x2="23" y2="12"></line>
                                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                            </svg>
                            <span class="ms-2">Light</span>
                        </a>
                        <a v-if="settings.dark_mode == 'dark'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('light')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-moon"
                            >
                                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                            </svg>
                            <span class="ms-2">Dark</span>
                        </a>
                    </div>
                    <div class="dropdown nav-item language-dropdown btn-group">
                        <a href="javascript:;" id="ddllang" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
                            <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)" class="flag-width" alt="flag" />
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="ddllang">
                            <perfect-scrollbar>
                                <li v-for="item in countryList" :key="item.code">
                                    <a href="javascript:;" class="dropdown-item d-flex align-items-center" :class="{ active: $i18n.locale === item.code }" @click.prevent="changeLanguage(item)">
                                        <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width" alt="" /> <span>{{ item.name }}</span>
                                    </a>
                                </li>
                            </perfect-scrollbar>
                        </ul>
                    </div>

                    <Notification />

                    <div class="dropdown nav-item user-profile-dropdown btn-group">
                        <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only user nav-link">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#fff"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-user"
                            >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
                            <li role="presentation">
                                <router-link to="/users/profile" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-user"
                                    >
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    Profile
                                </router-link>
                            </li>
                            <li role="presentation">
                                <router-link :to="{name: 'edit-profile'}" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-user"
                                    >
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    Edit Profile
                                </router-link>
                            </li>

                            <li role="presentation">
                                <a @click="signOut()" href="javascript:void(0)" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-log-out"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                        <polyline points="16 17 21 12 16 7"></polyline>
                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                    </svg>
                                    Sign Out
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="settings.toggleSideBar(!settings.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id="breadcrumb" class="vue-portal-target">
                    <ul class="navbar-nav flex-row">
                        <li>
                            <div class="page-header">
                                <nav class="breadcrumb-one" aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item active">
                                            <a href="javascript:;">{{ crumb.name }}</a>
                                            <span v-if="index < breadcrumbs.length - 1" class="breadcrumb-slash">/</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </li>
                    </ul>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <div class="topbar-nav header navbar" role="banner">
            <nav class="topbar">
                <ul class="list-unstyled menu-categories" id="topAccordion">
                    <!-- <li class="menu single-menu">
                        <a href="javascript:;" class="dropdown-toggle autodroprown">
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-user"
                                >
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                <span>Management</span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                         <ul class="collapse submenu list-unstyled">
                            <li>
                                <router-link :to="{ name: 'employee' }">Employees</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'leave' }">Day Offs</router-link>
                            </li>
                        </ul>
                    </li> -->
                </ul>
            </nav>
        </div>
    </div>
</template>

<script setup>
    import { useSettings } from '@/store';
    import { useMe } from '@/store/me';
    import { onMounted, ref, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { logoutApi } from '@/api/auth';
    import { getMeApi, getMeJobCodeApi, getMeEntitiesApi, changeEntityApi } from '@/api/me';
    import { useAuth } from '@/store/auth';
    import { useRouter, useRoute } from 'vue-router';

    import useDetectOutsideClick from '@/composables/useDetectOutsideClick';
    import Notification from '@/components/header/Notification.vue';

    const entityOptionsRef = ref();

    const settings = useSettings();
    const authStore = useAuth();
    const router = useRouter();
    const route = useRoute();

    const meStore = useMe();

    const selectedLang = ref(null);
    const countryList = ref(settings.countryList);
    const entityList = ref([]);
    const meEntity = ref({});

    const showEntityDropdown = ref(false);

    const i18n = useI18n();

    onMounted(async () => {
        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();

        if (authStore.refreshToken) {
            const isPasswordExpired = JSON.parse($cookies.get('isPasswordExpired'));
            meStore.setPasswordExpiration(isPasswordExpired);

            const [{ data: entity }, { data: meInfo }, { data: jobCodes }] = await Promise.all([getMeEntitiesApi(), getMeApi(), getMeJobCodeApi()]);
            entityList.value = entity.result;
            meEntity.value = meInfo.result.latest_login_entity;
            meStore.setMeJobCodes(jobCodes.result)

            meStore.setMeInfo(meInfo.result);
        }
    });

    const breadcrumbs = computed(() => {
        if (route.meta.breadcrumb && route.meta.breadcrumb.length) {
            return route.meta.breadcrumb;
        }
        return [];
    });

    //點擊外面關閉entity options list
    useDetectOutsideClick(entityOptionsRef, () => {
        showEntityDropdown.value = false;
    });

    const toggleEntityDropdown = () => {
        if (entityList.value.length > 1) {
            showEntityDropdown.value = !showEntityDropdown.value;
        }
    };

    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };

    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);

        i18n.locale.value = item.code;
    };

    const signOut = async () => {
        const isLoggingIn = $cookies.get('loggedIn');
        const refreshToken = $cookies.get('refreshToken');
        if (isLoggingIn && refreshToken) {
            await logoutApi();
        }
        authStore.clearAuth();
        router.push({ name: 'login' });
    };

    const changeEntity = async (entity) => {
        if (entity.code === meEntity.value.code) {
            return;
        }
        await changeEntityApi(entity.id);
        try {
            window.location.replace('/');
        } catch (error) {
            console.log(error);
        }
    };
</script>
<style lang="scss" scoped>
    .entity-select {
        cursor: pointer;
        position: relative;
        background: #2d2d2d;
        border-radius: 5px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        span {
            font-size: 20px;
        }
        .entity-name {
            margin: 0 8px;
            color: #fff;
        }
        .arrow {
            transition: transform 0.5s ease;
            font-size: 13px;
            color: #fff;
        }
        .arrow-up {
            transform: rotate(180deg);
        }
    }
    .single-entity {
        cursor: default;
    }
    @media screen and (max-width: 768px) {
        .entity-select {
            background: none;
            .entity-name {
                display: none;
            }
            .arrow {
                display: none;
            }
            .arrow-up {
                transform: rotate(180deg);
            }
        }
    }
    @media screen and (max-width: 768px) {
        .entity-name {
            display: none;
        }
    }
    .entities {
        position: absolute;
        top: 100%;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 10px 30px 0 rgb(31 45 61 / 10%);
        .ps {
            max-height: 300px;
            overflow: auto;
        }
        .entity {
            padding: 15px 5px 15px 20px;
            display: flex;
            cursor: pointer;
            &:hover {
                background: $m-color_2;
            }
            .fa-circle {
                font-size: 6px;
                margin: 5px 8px 0 0;
            }
            h6 {
                font-size: 14px;
                margin: 0;
                font-weight: 600;
            }
        }
        .entity-chosen {
            h6 {
                color: $info;
            }
        }
    }
    // @media screen and (max-width: 576px) {
    //     .entities {
    //         right: 0;
    //     }
    // }

    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.6s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transform: translateY(5%);
    }

    .breadcrumb-item.active {
        &::before {
            display: none !important;
        }
    }
    .breadcrumb-slash {
        margin: 0 6px;
        font-weight: bold;
    }
    @media print {
        .header-wrapper {
            display: none;
        }
    }
</style>
